import { Controller } from "stimulus";
import * as bulmaToast from "bulma-toast";
import i18n from "../config/i18n";

export default class extends Controller {
  static targets = ["modal", "deleteModal"];

  connect() {
    //Invoke bulma toast notifications, if any
    var message = this.data.get("message");
    var messageType = this.data.get("message-type");
    if (message && messageType) {
      bulmaToast.toast({
        message: message,
        position: "top-center",
        type: "is-" + messageType,
        duration: 100000,
      });
    }
  }

  /**
   * toggleModal
   * @param {*} e - Event
   * Toggle the is-active class to hide and show a modal for the given passed in data-id
   */
  toggleModal(e) {
    let modalId = e.currentTarget.dataset.id;
    document.getElementById(modalId).classList.toggle("is-active");
  }

  /**
   * toggleDeleteModal
   * @param {*} e - Event
   * Toggle the is-active class to hide and show a delete modal for the given passed in data-class and data-path
   */
  toggleDeleteModal(e) {
    let modalId = "delete-modal",
      modal = document.getElementById(modalId);

    let modalTitle = modal.querySelector("#modal-title"),
      modalMessage = modal.querySelector("#modal-message"),
      modalLink = modal.querySelector("#modal-link"),
      klass = e.currentTarget.dataset.class,
      path = e.currentTarget.dataset.path;

    if (klass && path) {
      modalTitle.innerHTML = `${i18n.t("actions.delete", {
        model: i18n.t("activerecord.models." + klass + ".one"),
      })}`;
      modalMessage.innerHTML = i18n.t("messages.delete_" + klass);
      modalLink.href = path;
    } else {
      modalTitle.innerHTML = "";
      modalMessage.innerHTML = "";
      modalLink.href = "";
    }

    modal.classList.toggle("is-active");
  }
}
