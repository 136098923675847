import { Controller } from "stimulus"
import i18n from "../config/i18n";

export default class extends Controller {
  static targets = [ 'submit', 'attachment', 'newreceipt', 'filename' ]

  connect() {
    const attachmentModal = document.getElementById("notAttachmentsAuthorizedFlashModal");
    const modal = document.getElementById("flashModal");

    if (attachmentModal && modal) {
      attachmentModal.remove();
    } else if (attachmentModal) {
      attachmentModal.id = "flashModal";
      attachmentModal.classList.remove("is-active");
    }
  }

  create() {
    this.submitTarget.classList.add('is-loading')
    if (this.attachmentTarget.value != '') {
      this.attachmentTarget.parentNode.classList.add('is-hidden')
      this.submitTarget.parentNode.insertAdjacentHTML(
        "afterend",
        `
        <div class="control" style="display: flex; align-items: center">
          ${i18n.t("messages.uploading_attachment")}
        </div>
      `
      );
    }
  }

  // When an attachment file is selected, set the file-name field
  attachmentSelected() {
    var filename = this.attachmentTarget.value;
    var lastIndex = filename.lastIndexOf("\\");
    if (lastIndex >= 0) {
      filename = filename.substring(lastIndex + 1);
      filename = filename;
    }
    this.newreceiptTarget.innerHTML = `${i18n.t("actions.adding", {
      model: i18n.t("activerecord.attributes.transaction.receipt"),
    })}`;
    this.filenameTarget.innerHTML = filename;
  }

  attachmentDisabled() {
    event.preventDefault();

    const modal = document.getElementById("flashModal");

    modal.classList.add("is-active");
  }
}