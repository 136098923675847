import { Controller } from "stimulus";
import i18n from "../config/i18n";

export default class extends Controller {
  static targets = ["modal"];

  view(event) {
    event.preventDefault();
    const emailId = event.currentTarget.dataset.emailId;
    // Assuming you have a modal in your HTML with an ID of 'emailModal'
    const modal = document.getElementById("emailModal");
    // Optionally load email content dynamically and then display it
    this.loadEmailContent(emailId, modal);
  }

  loadEmailContent(emailId, modal) {
    fetch(`/emails/${emailId}`) // Adjust URL as necessary
      .then((response) => response.text())
      .then((html) => {
        modal.querySelector(".card-content").innerHTML = html;
        modal.classList.add("is-active");
      });
  }

  closeModal() {
    const modal = document.getElementById("emailModal");

    modal.classList.remove("is-active");
  }

  delete(event) {
    event.preventDefault();
    const emailId = event.currentTarget.dataset.emailId;
    const emailRows = document.querySelectorAll(
      `tr[data-email-id="${emailId}"]`
    );

    if (confirm(i18n.t("messages.delete_email"))) {
      // Perform the fetch request to delete the email
      fetch(`/emails/${emailId}`, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": this.getMetaValue("csrf-token"), // Ensure CSRF token is included for Rails
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response.ok) {
          emailRows.forEach((row) => row.remove());
        }
      });
    }
  }

  // Helper method to get meta values (e.g., CSRF token)
  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }
}
