import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["flashModal"];

  connect() {
    if (this.hasFlashModalTarget) {
      this.showModal();
    }
  }

  showModal() {
    const modal = document.getElementById("flashModal");

    modal.classList.add("is-active");
  }

  closeModal() {
    const modal = document.getElementById("flashModal");

    modal.classList.remove("is-active");
  }
}
