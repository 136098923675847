// app/javascript/controllers/base_tour_controller.js
import ApplicationController from "./application_controller";
import Shepherd from "shepherd.js";

export default class BaseTourController extends ApplicationController {
  static values = { tourKey: String }; // Adding a value for tour key
  tour; // Define tour at the controller level

  connect() {
    const tourKey = this.tourKeyValue || "defaultTourTaken"; // Use a default key if none provided
    const tourButton = document.querySelector("a#start-tour");
    const env = document.querySelector("meta[name='env']")?.content;

    if (env === "test") {
      return;
    }

    if (tourButton) {
      tourButton.classList.remove("is-hidden");
      tourButton.addEventListener("click", () => {
        this.showTour(tourKey);
      });
    }
    
    if (
      !localStorage.getItem("skipGlobalTour") &&
      localStorage.getItem(tourKey) !== "true"
    ) {
      this.showTour(tourKey);
    }
  }

  showTour(tourKey) {
    this.tour = new Shepherd.Tour({
      defaultStepOptions: {
        cancelIcon: { enabled: true },
        classes: "shadow-md",
        scrollTo: { behavior: 'smooth', block: 'center' }
      },
      useModalOverlay: true,
      exitOnEsc: true,
      showCounter: true,
    });
    this.tour.addSteps(this.steps());
    this.tour.start();
    localStorage.setItem(tourKey, "true");
  }

  // To be overridden by subclasses
  steps() {
    return [];
  }
}
