import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  // Extend from ApplicationController
  static targets = ["form"];

  async save(event) {
    event.preventDefault(); // Prevent default form submission

    const formElement = event.currentTarget;

    try {
      const response = await fetch(formElement.action, {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
          Accept: "application/json",
        },
        body: new FormData(formElement),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Reset the form after Reflex completes
      formElement.reset();

      // Trigger the Reflex after successful form submission
      this.stimulate("ApplicationReflex#refresh");
    } catch (error) {
      console.error("Error saving change:", error);
    }
  }
}
