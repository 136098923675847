// app/javascript/controllers/tabs_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];

  connect() {
    this.showTabFromUrlOrDefault(); // Adjusted method to check URL and show the appropriate tab
  }

  change(event) {
    event.preventDefault();
    const targetId = event.currentTarget.dataset.tab;

    this.switchTab(targetId);
    this.updateUrl(targetId); // Update the URL when the tab is changed
  }

  showTabFromUrlOrDefault() {
    const params = new URLSearchParams(window.location.search);
    const tabId = params.get("tab");

    if (tabId) {
      // Show the tab specified in the URL
      this.switchTab(tabId);
    } else {
      // No tab param in URL, show the first tab
      this.showActiveTab();
    }
  }

  showActiveTab() {
    // Find the first tab and display it
    const firstTab = this.tabTargets[0];

    if (firstTab) {
      this.switchTab(firstTab.dataset.tab);
    }
  }

  switchTab(tabId) {
    // Activate the selected tab and deactivate others
    this.tabTargets.forEach((tab) => {
      tab.classList.toggle("is-active", tab.dataset.tab === tabId);
    });

    // Show the selected tab's content and hide others
    this.contentTargets.forEach((content) => {
      content.classList.toggle("is-hidden", content.id !== tabId);
    });
  }

  updateUrl(tabId) {
    const url = new URL(window.location);
    url.searchParams.set("tab", tabId);
    window.history.pushState({}, "", url); // Update the browser URL without reloading the page
  }
}
