// app/javascript/controllers/accounts_new_tour_controller.js
import BaseTourController from "./base_tour_controller";
import i18n from "../config/i18n";

export default class AccountsNewTourController extends BaseTourController {
  // Define specific steps for the 'new account' tour
  steps() {
    return [
      {
        title: i18n.t("tour.account_creation.title"),
        text: i18n.t("tour.account_creation.step1.text"),
        attachTo: {
          element: "input[name='account[name]']",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.cancel();
            },
            text: i18n.t("tour.buttons.cancel"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.account_creation.title"),
        text: i18n.t("tour.account_creation.step2.text"),
        attachTo: {
          element: "select[name='account[account_type]']",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.account_creation.title"),
        text: i18n.t("tour.account_creation.step3.text"),
        attachTo: {
          element: "div#account_number_fields",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.account_creation.title"),
        text: i18n.t("tour.account_creation.step4.text"),
        attachTo: {
          element: "div.account_balance_fields",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.finish"),
          },
        ],
      },
    ];
  }
}
