// app/javascript/controllers/emails_index_tour_controller.js
import BaseTourController from "./base_tour_controller";
import i18n from "../config/i18n";

export default class EmailIndexTour extends BaseTourController {
  // Define the steps for the for the 'budgets index' tour

  steps() {
    if (document.querySelector("table#emails-table")) {
      return this.emailsSteps();
    } else {
      return this.noEmailSteps();
    }
  }

  emailsSteps() {
    let actionsSection, linkedTransaction;

    if (window.innerWidth < 768) {
      actionsSection = "div.email-actions";
      linkedTransaction = ".autogenrated-transaction-link.is-mobile";
    } else {
      actionsSection = "td.email-actions";
      linkedTransaction = ".autogenrated-transaction-link.not-mobile";
    }

    return [
      {
        title: i18n.t("tour.emails_index.title_received"),
        text: i18n.t("tour.emails_index.text_received"),
        buttons: [
          {
            action() {
              return this.cancel();
            },
            text: i18n.t("tour.buttons.cancel"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.emails_index.title_details"),
        text: i18n.t("tour.emails_index.text_click_details"),
        attachTo: { element: "#emails-table", on: "top" },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.emails_index.title_details"),
        text: i18n.t("tour.emails_index.text_scheduled_emails"),
        attachTo: { element: "#emails-table", on: "top" },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.emails_index.title_details"),
        text: i18n.t("tour.emails_index.text_successful_emails"),
        attachTo: { element: linkedTransaction, on: "top" },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.emails_index.title_details"),
        text: i18n.t("tour.emails_index.text_edit_transactions"),
        attachTo: { element: linkedTransaction, on: "top" },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.emails_index.title_details"),
        text: i18n.t("tour.emails_index.text_failed_emails"),
        attachTo: { element: actionsSection, on: "top" },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.finish"),
          },
        ],
      },
    ];
  }

  noEmailSteps() {
    // Placeholder for steps when no emails are present
    return [];
  }
}
