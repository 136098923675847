// app/javascript/i18n.js
import { I18n } from "i18n-js";
import locales from "../locales.json";

const currentLocale = document
  .querySelector('meta[name="locale"]')
  .getAttribute("content");
const i18n = new I18n(locales);

i18n.defaultLocale = "es";
i18n.locale = currentLocale;

export default i18n;
