// app/javascript/controllers/transactions_index_tour_controller.js
import BaseTourController from "./base_tour_controller";
import i18n from "../config/i18n";

export default class TransactionsIndexTour extends BaseTourController {
  // Updated class name
  static values = {
    tourKey: String,
    defaultCurrency: String,
    hasTransactions: Boolean,
  };

  steps() {
    if (this.hasTransactionsValue) {
      return this.transactionsSteps();
    } else {
      return this.noTransactionSteps();
    }
  }

  transactionsSteps() {
    return [
      {
        title: i18n.t("tour.transactions.title"),
        text: i18n.t("tour.transactions.manage_transactions.text"),
        attachTo: {
          element: "#transactions-list",
          on: "top",
        },
        buttons: [
          {
            action() {
              return this.cancel();
            },
            text: i18n.t("tour.buttons.cancel"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      this.balanceStep(), // Fixed function reference
      this.transferStep(), // Fixed function reference
      this.fixBalanceStep(), // Fixed function reference
      {
        title: i18n.t("tour.transactions.title"),
        text: i18n.t("tour.transactions.stashes.text"),
        attachTo: {
          element: "#stashes-dropdown",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.finish"),
          },
        ],
      },
    ];
  }

  noTransactionSteps() {
    let transactionButton;

    if (window.innerWidth < 768) {
      transactionButton = "a.button.is-small.is-link.new-transaction.is-mobile";
    } else {
      transactionButton = "a.button.is-link.new-transaction.not-mobile";
    }

    return [
      {
        title: i18n.t("tour.transactions.title"),
        text: i18n.t("tour.transactions.no_transactions.intro.text"),
        buttons: [
          {
            action() {
              return this.cancel();
            },
            text: i18n.t("tour.buttons.cancel"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      this.balanceStep(), // Fixed function reference
      this.transferStep(), // Fixed function reference
      this.fixBalanceStep(), // Fixed function reference
      {
        title: i18n.t("tour.transactions.title"),
        text: i18n.t("tour.transactions.no_transactions.new_transaction.text"),
        attachTo: {
          element: transactionButton,
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.finish"),
          },
        ],
      },
    ];
  }

  balanceStep() {
    let balanceBox;

    if (window.innerWidth < 768) {
      balanceBox = "button#balance-box";
    } else {
      balanceBox = "div#balance-box";
    }

    return {
      title: i18n.t("tour.transactions.title"),
      text: i18n.t("tour.transactions.no_transactions.balance.text", {
        currency: this.defaultCurrencyValue,
      }),
      attachTo: {
        element: balanceBox,
        on: "bottom",
      },
      buttons: [
        {
          action() {
            return this.back();
          },
          text: i18n.t("tour.buttons.previous"),
          secondary: true,
        },
        {
          action() {
            return this.next();
          },
          text: i18n.t("tour.buttons.next"),
        },
      ],
    };
  }

  transferStep() {
    let transferButton;

    if (window.innerWidth < 768) {
      transferButton =
        "button#transfer-button.button.is-link.is-outlined.is-small";
    } else {
      transferButton = "button#transfer-button.button.is-link.is-outlined";
    }

    return {
      title: i18n.t("tour.transactions.title"),
      text: i18n.t("tour.transactions.no_transactions.transfer.text"),
      attachTo: {
        element: transferButton,
        on: "bottom",
      },
      buttons: [
        {
          action() {
            return this.back();
          },
          text: i18n.t("tour.buttons.previous"),
          secondary: true,
        },
        {
          action() {
            return this.next();
          },
          text: i18n.t("tour.buttons.next"),
        },
      ],
    };
  }

  fixBalanceStep() {
    let fixBalanceButton;

    if (window.innerWidth < 768) {
      fixBalanceButton =
        "button#fix-balance-button.button.is-link.is-outlined.is-small";
    } else {
      fixBalanceButton = "button#fix-balance-button.button.is-link.is-outlined";
    }

    return {
      title: i18n.t("tour.transactions.title"),
      text: i18n.t("tour.transactions.no_transactions.fix_balance.text"),
      attachTo: {
        element: fixBalanceButton,
        on: "bottom",
      },
      buttons: [
        {
          action() {
            return this.back();
          },
          text: i18n.t("tour.buttons.previous"),
          secondary: true,
        },
        {
          action() {
            return this.next();
          },
          text: i18n.t("tour.buttons.next"),
        },
      ],
    };
  }
}
