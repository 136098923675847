// app/javascript/controllers/transactions_new_tour_controller.js
import BaseTourController from "./base_tour_controller";
import i18n from "../config/i18n";

export default class TransactionsNewTour extends BaseTourController {
  // Define specific steps for the 'new transaction' tour
  steps() {
    return [
      {
        title: i18n.t("tour.new_transaction.title"),
        text: i18n.t("tour.new_transaction.step1.text"),
        attachTo: {
          element: ".field.trx_type",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.cancel();
            },
            text: i18n.t("tour.buttons.cancel"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.new_transaction.title"),
        text: i18n.t("tour.new_transaction.step2.text"),
        attachTo: {
          element: ".columns.trx_date_and_account_balance",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.new_transaction.title"),
        text: i18n.t("tour.new_transaction.step3.text"),
        attachTo: {
          element: ".columns.trx_currency_and_amount",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.new_transaction.title"),
        text: i18n.t("tour.new_transaction.step4.text"),
        attachTo: {
          element: "select[name='transaction[category_id]']",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.new_transaction.title"),
        text: i18n.t("tour.new_transaction.step5.text"),
        attachTo: {
          element: "input[name='transaction[description]']",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.finish"),
          },
        ],
      },
    ];
  }
}

