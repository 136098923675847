// app/javascript/controllers/budgets_index_tour_controller.js
import BaseTourController from "./base_tour_controller";
import i18n from "../config/i18n";

export default class BudgetsIndexTour extends BaseTourController {
  // Define specific steps for the 'budgets index' tour
  steps() {
    let newBudgetButton;

    if (window.innerWidth < 768) {
      newBudgetButton = "a.new-budget.is-mobile";
    } else {
      newBudgetButton = "a.new-budget.not-mobile";
    }

    return [
      {
        title: i18n.t("tour.budgets.title"),
        text: i18n.t("tour.budgets.intro.text"),
        buttons: [
          {
            action() {
              return this.cancel();
            },
            text: i18n.t("tour.buttons.cancel"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.budgets.title"),
        text: i18n.t("tour.budgets.create_budget.text"),
        attachTo: {
          element: newBudgetButton,
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.finish"),
          },
        ],
      },
    ];
  }
}
