import { Controller } from "stimulus";
import i18n from "../config/i18n";

export default class extends Controller {
  static targets = ["emails"];

  addEmailField(event) {
    if (event) {
      event.preventDefault();
    }

    const lastFieldGroup = this.emailsTarget.querySelector(
      ".email-fields:first-child"
    );
    const newIndex = this.getNewIndex(lastFieldGroup);
    const newFieldGroup = this.cloneFieldGroup(lastFieldGroup, newIndex);

    this.updateFieldGroup(newFieldGroup, newIndex);
    this.emailsTarget.appendChild(newFieldGroup);
  }

  removeEmailField(event) {
    event.preventDefault();

    const emailFields = this.emailsTarget.querySelectorAll(
      ".email-fields:not(.is-hidden)"
    );
    const emailFieldGroup = event.target.closest(".email-fields");
    const idInput = this.getIdInput(emailFieldGroup);
    const destroyInput = this.getDestroyInput(emailFieldGroup);

    if (idInput && idInput.value !== '' && destroyInput) {
      destroyInput.value = "1";

      // Submit the form
      this.emailsTarget.closest("form").submit();
    }

    // Hide the field group
    emailFieldGroup.classList.add("is-hidden");

    if (emailFields.length === 1) {
      this.addEmailField(event);
    }
  }

  getNewIndex(lastFieldGroup) {
    const nameMatch = lastFieldGroup
      .querySelector("input")
      .name.match(/\[(\d+)\]/);
    const lastIndex = nameMatch ? parseInt(nameMatch[1], 10) : 0;
    return lastIndex + 1;
  }

  cloneFieldGroup(lastFieldGroup, newIndex) {
    const newFieldGroup = lastFieldGroup.cloneNode(true);
    this.removeIds(newFieldGroup);
    return newFieldGroup;
  }

  updateFieldGroup(newFieldGroup, newIndex) {
    const emailInput = newFieldGroup.querySelector("input[type='email']");
    const helpText = newFieldGroup.querySelector(".help");
    const oldId = emailInput.id || "";
    const newId = oldId.replace(/_\d+$/, `_${newIndex}`);

    emailInput.id = newId;
    emailInput.name = emailInput.name.replace(/\[\d+\]/, `[${newIndex}]`);
    emailInput.value = "";
    emailInput.removeAttribute("disabled");
    helpText.textContent = i18n.t("help.user.new_email");
    helpText.classList.remove("has-text-danger");
    helpText.classList.remove("has-text-success");

    // Remove the hidden ID input for new records
    const idInput = this.getIdInput(newFieldGroup);
    if (idInput) {
      idInput.remove();
    }

    // Remove the hidden _destroy input for new records
    const destroyInput = this.getDestroyInput(newFieldGroup);
    if (destroyInput) {
      destroyInput.remove();
    }

    const actionsElement = newFieldGroup.querySelector(".email-field-actions");
    if (actionsElement) {
      actionsElement.classList.remove("is-hidden");
    }

    newFieldGroup.classList.remove("is-hidden");
  }

  removeIds(element) {
    element
      .querySelectorAll("[id]")
      .forEach((node) => node.removeAttribute("id"));
    element
      .querySelectorAll("[for]")
      .forEach((node) => node.removeAttribute("for"));
  }

  getIdInput(element) {
    const hiddenInputs = element.querySelectorAll(
      "input[type='hidden'][name$='[id]']"
    );
    return hiddenInputs[0];
  }

  getDestroyInput(element) {
    const hiddenInputs = element.querySelectorAll(
      "input[type='hidden'][name$='[_destroy]']"
    );
    return hiddenInputs[0];
  }
}
